<template>
  <div class="quiz-content-container">
    <div :class="[`quiz-content-container__inner ${getClassName()}`]">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuizContentContainer',
  prop: ['class'],
  methods: {
    getClassName() {
      return this.class || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.quiz-content-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__inner {
    width: 100%;
    max-width: 368px;
  }
}

@media (max-width: $breakpoint-tablet) {
  .welcome-theme {
    .quiz-content-container {
      background: $sky;
    }
  }
}
</style>
