<template>
  <ResponsiveLayout>
    <div class="download-link">
      <div
        v-if="showSpinner"
        class="download-link__spinner"
      >
        <Base64Spinner />
      </div>
      <div
        v-else
        class="kds-main-wrapper"
      >
        <h2
          class="kds-typography-display-small"
          v-html="headerText"
        />
        <BaseBanner
          variant="success"
          :title="infoSectionHeader"
          :description="infoSectionDescription"
        />
        <div
          v-if="state !== STATES.IN_APP"
          class="kds-content-block"
        >
          <p
            class="kds-typography-display-small"
            v-html="ctrlsHeaderText"
          />
          <p
            class="kds-typography-paragraph-large"
            v-html="ctrlsDescriptionText"
          />
        </div>

        <div
          v-if="state === STATES.DESKTOP_BROWSER"
          class="download-link__qr-codes qr-codes"
        >
          <img
            class="qr-codes__item"
            :src="appleQRCodeUrl"
            alt="Kaia iOS QR Code"
          />
          <img
            class="qr-codes__item"
            :src="googleQRCodeUrl"
            alt="Kaia Android QR Code"
          />
        </div>

        <div
          v-if="state !== STATES.IN_APP"
          class="download-link__store"
        >
          <StoreBadge
            v-if="showAppStoreLink"
            class="download-link__store-item"
            :lang="lang"
            app-type="ios"
            @click="handleStoreLinkClick('App Store')"
          />
          <StoreBadge
            v-if="showPlayStoreLink"
            class="download-link__store-item"
            :lang="lang"
            app-type="android"
            @click="handleStoreLinkClick('Google Play')"
          />
        </div>
        <div
          v-if="state === STATES.IN_APP"
          class="download-link__redirect-cta"
        >
          <BaseButton
            :text="redirectDeepLinkText"
            @click="handleRedirectLinkClickEvent"
          />
        </div>
      </div>
    </div>
  </ResponsiveLayout>
</template>

<script setup>
import { useStore } from 'vuex'
import { isMobile, isAndroidAgent, logW } from '@shared/utils'
import { isFromApp } from '@shared/helpers/isFromApp'
import { useI18n } from 'petite-vue-i18n'

import env from '@shared/env.js'
import assembleKaiaAppDeepLink from '@shared/helpers/assembleKaiaAppDeepLink.js'
import getQRCodeUrl from '@shared/helpers/getQRCodeUrl.js'

import Base64Spinner from '@shared/components/Base64Spinner.vue'
import BaseButton from '@shared/components/BaseButton.vue'
import Tracker from '@shared/Tracker'
import StoreBadge from '@shared/components/StoreBadge.vue'
import BaseBanner from '@shared/components/BaseBanner.vue'
import ResponsiveLayout from '@shared/components/ResponsiveLayout.vue'
import { computed, onMounted, ref } from 'vue'

const { t } = useI18n()
const store = useStore()

const STATES = {
  IN_APP: 0,
  MOBILE_BROWSER: 1,
  DESKTOP_BROWSER: 2,
}
const state = isFromApp()
  ? STATES.IN_APP
  : isMobile()
    ? STATES.MOBILE_BROWSER
    : STATES.DESKTOP_BROWSER
const showPlayStoreLink = state === STATES.DESKTOP_BROWSER || isAndroidAgent()
const showAppStoreLink = state === STATES.DESKTOP_BROWSER || !isAndroidAgent()

const showSpinner = ref(false)
const user = computed(() => store.getters.user)
const lang = computed(() => store.getters.lang)
const corporate = computed(() => store.getters.corporate)
const formData = computed(() => store.getters.formData)
const redirectLink = computed(() => store.getters.redirectLink)
const setRedirectLink = (link) => store.commit('redirectLink', link)

const appleQRCodeUrl = computed(() => getQRCodeUrl(lang.value, 'ios'))
const googleQRCodeUrl = computed(() => getQRCodeUrl(lang.value, 'android'))

const headerText = computed(() => {
  if (state === STATES.IN_APP) {
    return user.value == null
      ? t('us__download_page__header__deeplink__no_user')
      : t('us__download_page__header__deeplink__with_user')
  }

  return user.value == null
    ? t('us__download_page__header__no_user')
    : t('us__download_page__header__with_user')
})
const ctrlsHeaderText = t('download_page__ctrls_header', {
  app_name: t('eu__generic__app'),
})
const ctrlsDescriptionText = t('download_page__ctrls_description')
const infoSectionHeader = computed(() => {
  if (state === STATES.IN_APP) {
    return user.value == null
      ? t('us__download_page__info_header__deeplink__no_user')
      : t('us__download_page__info_header__deeplink__with_user')
  }
  return user.value == null
    ? t('us__download_page__info_header__no_user')
    : t('us__download_page__info_header__with_user')
})
const infoSectionDescription = computed(() => {
  if (state === STATES.IN_APP) {
    return user.value == null
      ? t('us__download_page__info_description__deeplink__no_user', {
          support_email: env.VITE_SUPPORT_EMAIL,
        })
      : t('us__download_page__info_description__deeplink__with_user')
  }

  return user.value == null || formData.value.email == null
    ? t('us__download_page__info_description__no_user', {
        support_email: env.VITE_SUPPORT_EMAIL,
      })
    : t('us__download_page__info_description__with_user', {
        user_email: formData.value.email,
      })
})
const redirectDeepLinkText = computed(() => {
  return user.value == null
    ? t('generic_action_continue')
    : t('b2b_get_started_btn_text')
})

const getDeepLink = async () => {
  if (user.value) {
    try {
      return assembleKaiaAppDeepLink('auth/one_time_token', {}, true)
    } catch (e) {
      logW(
        'failed to append oneTimeToken to deeplink, using fallback deeplink',
        e,
      )
      return assembleKaiaAppDeepLink('open')
    }
  }
  if (corporate.value) {
    return assembleKaiaAppDeepLink('signup/webcheckout', {
      corporate: corporate.value.key,
      from_app: true,
      skip_information: true,
    })
  }
  return assembleKaiaAppDeepLink('goto/screen', { target: 'login' })
}

const ensureDeepLink = async () => {
  // dont reload the deeplink if it already exists and was created less than 30 seconds ago
  if (redirectLink.value?.creationDate > Date.now() - 1000 * 30) {
    return
  }

  const link = await getDeepLink()
  setRedirectLink({
    link,
    creationDate: Date.now(),
  })
}

const automaticDeepLinkRedirect = () => {
  setTimeout(() => {
    showSpinner.value = false
  }, 1000)

  window.location.assign(redirectLink.value.link)
}

const handleStoreLinkClick = (storeName) => {
  Tracker.trackKaiaEvent(
    {
      event_name: 'c.lfcycl.select_button',
      app_area: 'lifecycle',
      action: 'view',
      object_type: 'screen',
      source: 'web-checkout',
      screen_name: 'signup_app_download',
    },
    {
      label: `Go to ${storeName}`,
      corporate_id: corporate.value.id,
      corporate_name: corporate.value.title,
    },
  )
}

const handleRedirectLinkClickEvent = () => {
  window.location.assign(redirectLink.value.link)
}

onMounted(async () => {
  if (state === STATES.IN_APP) {
    showSpinner.value = true
    await ensureDeepLink()
    automaticDeepLinkRedirect()
  }
})
</script>

<style lang="scss">
.download-link {
  &__spinner {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 64px;
    height: 64px;
    margin: -32px 0 0 -32px;
  }

  .qr-codes {
    display: none;
  }

  &__store {
    max-width: 75%;
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    &-item {
      width: 66%;
      height: 48px;
      margin-bottom: 8px;
    }
  }

  @media (min-width: $size-small) {
    .store-links {
      margin: 32px 0;

      &__item {
        height: 60px;
      }
    }
  }

  @media (min-width: $size-medium) {
    .qr-codes {
      max-width: 75%;
      display: flex;
      justify-content: space-between;

      &__item {
        width: 27%;
        padding: 0 32px;
      }
    }

    &__store {
      flex-direction: row;
      justify-content: space-between;

      &-item {
        width: 42%;
        margin-bottom: 0;
      }
    }
  }
}
</style>
