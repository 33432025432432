<template>
  <div class="confirmation-box">
    <div
      :class="[
        'form-checkbox',
        { 'form-checkbox__invalid': shouldAlertCheckbox },
      ]"
    >
      <div class="form-checkbox__layout">
        <custom-checkbox
          :name="checkboxId"
          :checked="checked"
          value="confirmed"
          :data-q-a="'confirmation-' + checkboxId"
          @change="handleChangeEvent"
        />
      </div>
      <label
        class="form-checkbox__label"
        :for="checkboxId"
        v-html="labelTitle"
      />
    </div>

    <button
      type="button"
      :class="['btn btn-secondary', { 'btn-disabled': !ctaEnabled }]"
      :disabled="!ctaEnabled"
      data-qa="submit"
      @click="handleClickEvent"
      v-html="ctaTitle"
    />
  </div>
</template>

<script>
import CustomCheckbox from '@shared/components/CustomCheckbox.vue'

export default {
  name: 'ConfirmationBox',
  components: {
    CustomCheckbox,
  },
  inheritAttrs: false,
  props: {
    labelTitle: {
      type: String,
      default: '',
    },
    ctaTitle: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
    ctaEnabled: {
      type: Boolean,
      default: false,
    },
    shouldAlertCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit', 'change'],
  data() {
    return {
      checkboxId: `id_${Date.now()}`,
    }
  },
  methods: {
    handleClickEvent(event) {
      if (this.checked) {
        this.$emit('submit', event)
      }
    },
    handleChangeEvent(event) {
      this.$emit('change', event)
    },
  },
}
</script>

<style lang="scss">
.form-checkbox {
  display: flex;

  .custom-checkbox {
    border-width: 2px;
  }

  &__layout {
    background-color: $white;
    border-radius: 3px;
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  &__label {
    color: $gray-65;
    padding-left: 13px;
    cursor: pointer;

    @include kaia-typography-p1($font-weight-normal);

    .a-link {
      color: $ocean;
      text-decoration: none;
      font-weight: 700;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__invalid {
    .custom-checkbox {
      border-color: $danger;
    }

    .form-checkbox__label {
      color: $danger;
    }
  }
}

.confirmation-box {
  display: flex;
  flex-direction: column;
  padding: 32px 26px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $sky;
    border-radius: $kds-shape-rounded-large;
    opacity: 0.6;
  }

  &__text {
    color: $midnight;
    position: relative;
    margin-bottom: 22px;

    @include kaia-typography-p1($font-weight-semi-bold);
  }

  @media (max-width: $breakpoint-tablet) {
    flex-direction: column;
  }

  .custom-checkbox {
    &--focused,
    &--checked {
      border-color: $midnight;
    }

    &--checked {
      background-color: $midnight;
    }
  }

  .btn {
    width: 250px;
    height: 58px;
    position: relative;
    border-radius: 50px;
    margin-left: 32px;
    background: $midnight;

    @media (max-width: $breakpoint-tablet) {
      width: 50%;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-left: 10px;
      background-image: url(@shared/assets/img/short_right_arrow.svg);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-disabled {
      background-color: $gray-5;
      border-color: $gray-5;
      color: $gray-35;

      &::after {
        background-position: left -24px;
      }
    }
  }
}

.form-checkbox {
  position: relative;
  margin-bottom: 20px;

  &__label {
    color: $midnight;

    @include kaia-typography-p1($font-weight-semi-bold);
  }
}
</style>
